
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Button from "primevue/button";
import Panel from "primevue/panel";
import Message from "primevue/message";
import Dialog from "primevue/dialog";
import useVuelidate from "@vuelidate/core";
import { required, sameAs } from "@vuelidate/validators";
import crypto from "crypto";
import Footer from "@/components/Footer.vue";
import LoginService from "@/services/LoginService";
import StatusService from "@/services/StatusService";
const service = new LoginService(process.env.VUE_APP_AZURE_FUNC_URL);
const statusService = new StatusService(process.env.VUE_APP_AZURE_FUNC_URL);
import LoginResponse from "@/types/response/login";
import { mapGetters } from "vuex";

const RESET_PASSWORD = "RESET_PASSWORD";

enum ButtonText {
  Login = "Login",
  Register = "Register",
  UpdatePassword = "Update Password",
}

export default defineComponent({
  name: "LoginForm",
  components: {
    InputText,
    Password,
    Button,
    Dialog,
    Panel,
    Footer,
    Message,
  },
  computed: {
    ...mapGetters({
      styles: "styles/getStyles"
    }),
    loginActive(): any {
      return {
        grey: this.buttonText !== ButtonText.Login,
        white: this.buttonText === ButtonText.Login,
      };
    },
    registerActive(): any {
      return {
        grey: this.buttonText !== ButtonText.Register,
        white: this.buttonText === ButtonText.Register,
      };
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      ButtonTextOptions: ButtonText,
      Email: "",
      Password: "",
      OldPassword: "",
      submitted: false,
      buttonText: "Login",
      PasswordMatch: "",
      error: "",
      loading: false,
      nonce: "",
      showPasswordValidationError: false,
      passwordError: "",
    };
  },
  async created() {
    await statusService
      .status()
      .catch((error) => {
        this.error = error.message || "Server error. Please try again later.";
      });
    setInterval(() => {
      statusService
      .status()
      .catch((error) => {
        this.error = error.message || "Server error. Please try again later.";
      });
    }, 240000);
  },
  validations() {
    return {
      Email: { required },
      OldPassword: { required : this.buttonText === ButtonText.UpdatePassword },
      Password: { required },
      PasswordMatch: { sameAsPassword: sameAs(this.Password) },
    };
  },
  methods: {
    handleLogin(
      isFormValid: boolean,
      isEmailValid: boolean,
      isPasswordValid: boolean
    ) {
      this.submitted = true;
      if (this.buttonText === ButtonText.Register && isFormValid) {
        this.postRegister();
      }
      if (this.buttonText === ButtonText.Login && isEmailValid && isPasswordValid) {
        this.postLogin();
      }
      if (this.buttonText === ButtonText.UpdatePassword && isFormValid) {
        this.postUpdatePassword();
      }
      return;
    },
    postRegister() {
      this.loading = true;
      service.register({
          Email: this.Email,
          EncryptedPassword: this.encryptPassword(this.Password),
          Client: this.styles.client_name,
        })
        .then((response: LoginResponse) => {
          if(response.error) {
            throw new Error(response.error);
          }

          this.doLogin(response);

        }).catch((error) => {
          this.error = error.message || "Server error. Please try again later.";
        }).finally(() => {
          this.loading = false;
        });
    },
    postLogin() {
      this.loading = true;
      service.login({
          Email: this.Email,
          Password: this.Password,
          EncryptedPassword: this.encryptPassword(this.Password),
          Client: this.styles.client_name,
        })
        .then((response: LoginResponse) => {
          if(response.error && response.action !== RESET_PASSWORD) {
            throw new Error(response.error);
          }
          
          if (response.action === RESET_PASSWORD) {
            this.buttonText = ButtonText.UpdatePassword;
            this.OldPassword = this.Password;
            this.Password = "";
            this.nonce = response.nonce;
            this.error = response.error || "You must update your password before proceeding.";
            return;
          }
          this.doLogin(response);

        }).catch((error) => {
          this.error = error.message || "Server error. Please try again later.";
        }).finally(() => {
          this.loading = false;
        });
    },
    postUpdatePassword() {
      this.loading = true;
      service.updatePassword({
          Email: this.Email,
          OldPassword: this.OldPassword,
          NewPassword: this.Password,
          Client: this.styles.client_name,
          Nonce: this.nonce,
        })
        .then((response: LoginResponse) => {

          if (response.action === RESET_PASSWORD) {
            this.buttonText = ButtonText.UpdatePassword;
            this.nonce = response.nonce;
          }

          if(response.error) {
            throw new Error(response.error);
          }

          this.doLogin(response);

        }).catch((error) => {
          this.passwordError = error.message || "Server error. Please try again later.";
          this.showPasswordValidationError = true;
        }).finally(() => {
          this.loading = false;
        });
    },
    doLogin(response: LoginResponse) {
      const url = this.getUrl(response);

      if (response.user?.commands_rights && !Object.keys(response.user.commands_rights).length) {
        // this is a hack to reduce backend integrations work and maintain forward/backwards compatibility with new commands rights feature
        response.user.commands_rights = {};
        response.user.commands = [];
      }

      const payload = {
        session: btoa(
          JSON.stringify({
            name: this.styles.client_name,
            user: response.user ? response.user : response.contact,
            customers: response.custs,
            subKey: response.subscription_key,
            roverJwt: response.token,
            informerJwtToken: response.informerJwtToken,
            subscriptionClient: response.subscriptionClient,
            loginUrl: "" + process.env.VUE_APP_LOGIN_URL,
            theme: {
              primaryColor: this.styles.primary_color,
              backgroundColor: this.styles.background_color,
              secondaryColor: this.styles.secondary_color,
              sidebarTextColor: this.styles.sidebar_text_color,
              logo: this.styles.logo,
              title: this.styles.client_name,
              sidebarBGColor: this.styles.sidebar_bg_color,
              tabLogo: this.styles.tab_logo,
            },
          })
        ),
      };

      const paramString = new URLSearchParams(payload);
      location.replace(url + "?" + paramString);
    },
    getUrl(response: LoginResponse) {
      if (response.user) {
        return new URL("" + process.env.VUE_APP_WEB_CLIENT_URL);
      } else if (response.contact) {
        return new URL("" + process.env.VUE_APP_CUSTOMER_PORTAL_URL);
      } else {
        throw new Error("No valid user");
      }
    },
    encryptPassword(password: string) {
      return crypto.createHash("sha512").update(password).digest("hex");
    },
  },
});
